import React from 'react';
import styled, { css } from 'styled-components';
import Imgix from 'react-imgix';

// check if we're doing server-side rendering or not
const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  require('lazysizes'); // eslint-disable-line global-require
  require('lazysizes/plugins/attrchange/ls.attrchange'); // eslint-disable-line global-require
}

const Picture = styled.picture`
  position: relative;
  display: block;
  overflow: hidden;

  ${props =>
    props.placeholder === 'product' &&
    `
    background: #e6e6e6;
  `}

  ${props =>
    props.placeholder === 'test' &&
    `
    background: red;
  `}

  .lazyload,
  .lazyloading {
    opacity: 0;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
`;

const Spacer = styled.div`
  width: 100%;
  padding-bottom: ${props => 100 / props.aspectRatio}%;
`;

const imageStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImgixImage = styled(Imgix)`
  ${imageStyles}
`;

const Image = styled.img`
  ${imageStyles}
`;

export default ({
  src,
  srcset,
  sizes,
  aspectRatio = 1,
  imgixParams = {},
  placeholder = null,
  alt = '',
  isImgix = true,
  ...props
}) => {
  let imageAspectRatio = aspectRatio;

  const baseImageProps = isImgix
    ? {
        src,
        sizes,
        imgixParams: {
          ...imgixParams,
          auto: 'format',
          q: 90,
        },
        htmlAttributes: {
          alt,
        },
      }
    : {
        src,
        sizes,
        srcset,
        alt,
      };

  // props based on server-side rendering
  /* eslint-disable no-lonely-if */
  let imageProps;

  if (!isBrowser) {
    imageProps = {
      ...baseImageProps,
      className: 'lazyloaded',
    };
  } else {
    if (isImgix) {
      imageProps = {
        ...baseImageProps,
        className: 'lazyload',
        attributeConfig: {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        },
      };
    } else {
      if (baseImageProps.srcset) {
        imageProps = {
          alt: baseImageProps.alt,
          'data-src': baseImageProps.src,
          'data-srcSet': baseImageProps.srcset,
          'data-sizes': baseImageProps.sizes,
          className: 'lazyload',
        };
      } else {
        imageProps = {
          alt: baseImageProps.alt,
          'data-src': baseImageProps.src,
          className: 'lazyload',
        };
      }
    }
  }
  /* eslint-enable no-lonely-if */

  // calculate aspect ratio based on imgix props
  if (imgixParams.ar) {
    const ar = imgixParams.ar.split(':');

    imageAspectRatio = ar[0] / ar[1];
  }

  return (
    <Picture placeholder={placeholder} {...props}>
      <Spacer aspectRatio={imageAspectRatio} />

      {isImgix ? <ImgixImage {...imageProps} /> : <Image {...imageProps} />}
    </Picture>
  );
};
