import dict from '@/dict';

/**
 * Gets price per Liter from price and bottle size
 *
 * @param {string} amount Decimal value from Shopify API
 * @param {string} bottleSize bottle size enum
 */
export const getPricePerLiter = (amount, bottleSize) => {
  const pricePerLiter =
    Math.round((1 / dict(bottleSize, 'bottleNumeric')) * amount * 100) / 100;

  return `${pricePerLiter}`;
};

/**
 * Formats price so it's always X.XX
 *
 * @param {string} amount Decimal value from Shopify API
 */
export const getSanitizedPrice = amount => {
  const priceParts = amount.split('.');
  const sanitizedPriceParts = [priceParts[0]];

  // make sure cents are always displayed with 2 digits
  if (priceParts.length === 1) {
    sanitizedPriceParts.push('00');
  } else if (priceParts[1].length === 1) {
    sanitizedPriceParts.push(`${priceParts[1]}0`);
  } else {
    sanitizedPriceParts.push(priceParts[1]);
  }

  return sanitizedPriceParts.join('.');
};

/**
 * Formats prices with amount value from Shopify
 *
 * @param {string} amount Decimal value from Shopify API
 * @param {boolean} perLiter if price per liter format should be returned
 */
export const getPriceString = (amount, perLiter = false) => {
  const price = getSanitizedPrice(amount);
  const priceFormat = perLiter ? '€/L' : '€';

  return `${price} ${priceFormat}`;
};

/**
 * Gets formated price per Liter string from price and bottle size
 *
 * @param {string} amount Decimal value from Shopify API
 * @param {string} bottleSize bottle size enum
 */
export const getPricePerLiterString = (amount, bottleSize) => {
  const pricePerLiterAmount = getPricePerLiter(amount, bottleSize);

  return getPriceString(pricePerLiterAmount, true);
};
